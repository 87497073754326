<ng-container *ngIf="orderCampaignState$ | async as orderCampaignState">
  <img *ngIf="!!orderCampaignState.loading" class="troodi-spinner" src="assets/img/troodi_spinner.gif" />
  <div
    *ngIf="!orderCampaignState.loading && !!orderCampaignState.failed && !orderCampaignState.loaded || (trainingLoadError$ | async)"
    class="container my-auto error-content">
    <div class="row card-container my-4 mx-auto">
      <p class="error">
        <span translate>ORDER.ERROR.CAMPAIGN_PRE</span>
        <a href="mailto:info@troodi.de">info@troodi.de</a>
        <span translate>ORDER.ERROR.CAMPAIGN_POST</span>
      </p>
    </div>
  </div>
  <ng-container
    *ngIf="!orderCampaignState.loading && !orderCampaignState.failed && !!orderCampaignState.loaded && (trainingLoadError$ | async) === undefined">
    <div class="container my-auto main-content">
      <div class="row intro my-4 my-sm-0">
        <ng-container *ngIf="orderCampaignState.results.intro[selectedLanguage] as content">
          <ng-container *ngIf="!!content.headline && !!content.content; else defaultIntro">
            <span class="headline col-12">{{content.headline}}</span>
            <div class="col-12 intro" [innerHtml]="content.content"></div>
          </ng-container>
          <ng-template #defaultIntro>
            <span class="headline col-12" translate>ORDER.HEADLINE</span>
            <div class="col-12 intro">
              <span translate>ORDER.INTRO_TEXT.PRE</span>
              <a class="textlink" target="_blank" rel="noopener noreferrer" href="https://troodi.de/download/15736/">
                <span translate>ORDER.INTRO_TEXT.LINK</span>
              </a>
              <span translate>ORDER.INTRO_TEXT.POST</span>
            </div>
          </ng-template>
        </ng-container>
      </div>
      <div class="row content-container my-4">
        <div class="col-12 col-lg-7 order-container">
          <div class="card-header" translate>ORDER.TITLE_FORM</div>
          <!-- TODO: In Material v12, there is an option to switch vertical and horizontal stepper. Use this for mobiles as soon as upgraded! -->
          <mat-horizontal-stepper class="py-3 card-content" #stepper (selectionChange)="checkAvailability($event)">
            <ng-template matStepperIcon="edit">
              <i-feather name="edit-2"></i-feather>
            </ng-template>
            <ng-template matStepperIcon="done">
              <mat-icon svgIcon="check"></mat-icon>
            </ng-template>
            <form [formGroup]="orderForm">
              <mat-step stepControl="bookingsStep">
                <div formGroupName="bookingsStep" class="order-form">
                  <ng-template matStepLabel>
                    <span translate>ORDER.STEP.BOOKING</span>
                  </ng-template>
                  <mat-form-field appearance="standard">
                    <mat-label translate>ORDER.LABEL.BOOKINGS</mat-label>
                    <mat-select [formControl]="bookings" multiple required>
                      <!-- 
                          Initially, show the label. After user selected something, it changes to a label of format
                          "[first selected training] (+x other[s])"
                        -->
                      <mat-select-trigger *ngIf="!!bookings && !!bookings.value[0]">
                        {{bookings.value[0].name}}
                        <span *ngIf="bookings.value?.length > 1">
                          <!-- this looks a bit awkward..., but ngx-translate doesn't support pluralization out of the box -->
                          <!-- also, the changedetection acts weird when using a parametrized translation that changes using a suffix only -->
                          (+{{bookings.value?.length - 1}} {{'ORDER.LABEL.SUFFIX_SELECT_MORE_' + (bookings.value?.length === 2 ? 'S' : 'P')
                          | translate}})
                        </span>
                      </mat-select-trigger>
                      <mat-option *ngFor="let training of (availableTrainings$ | async)" [value]="training">
                        {{training.name}} ({{ (1/100) * training.gross_price_amount_in_cents | currency:currencyCode }})
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="(bookings?.dirty || bookings?.touched) && bookings?.errors?.required">
                      <span translate>ERROR.BOOKINGS_REQUIRED</span>
                    </mat-error>
                    <mat-hint translate>ORDER.STEP.BOOKING</mat-hint>
                  </mat-form-field>
                  <mat-form-field appearance="standard">
                    <mat-label translate>ORDER.LABEL.COUPON</mat-label>
                    <input matInput #couponCode formControlName="coupon" type="text" />
                    <mat-error *ngIf="coupon?.errors?.invalid">
                      <span translate>ORDER.ERROR.COUPON_INVALID</span>
                    </mat-error>
                    <button mat-flat-button matSuffix color="accent" (click)="redeemCoupon(couponCode.value)">
                      <span translate>ORDER.LABEL.REDEEM</span>
                    </button>
                    <mat-hint translate>ORDER.LABEL.COUPON</mat-hint>
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="syrupish">
                    <input matInput #hInput name="a_password" formControlName="hInput" type="text" tabindex="-1" autocomplete="off" />
                    <mat-checkbox
                      formControlName="hCheckbox"
                      color="accent"
                      name="contact_me_by_fax_only"
                      tabindex="-1"
                      autocomplete="off"></mat-checkbox>
                  </mat-form-field>
                </div>
              </mat-step>
              <mat-step stepControl="userStep">
                <div formGroupName="userStep" class="order-form">
                  <ng-template matStepLabel>
                    <span translate>ORDER.STEP.USER.HEADLINE</span>
                  </ng-template>
                  <span class="sub-heading" translate>ORDER.STEP.USER.INTRO</span>
                  <mat-form-field appearance="standard">
                    <mat-label translate>LABEL.FIRSTNAME</mat-label>
                    <!-- Changing this input triggers a change for the fields in the 'invoice'-tab, too -->
                    <input matInput #fname formControlName="firstName" type="text" required (change)="valueChanged('fname', fname.value)" />
                    <mat-error *ngIf="(userFirstName?.dirty || userFirstName?.touched) && userFirstName?.errors?.required">
                      <span translate>ORDER.HINT.FIRSTNAME_USER</span>
                    </mat-error>
                    <mat-hint translate>ORDER.HINT.FIRSTNAME_USER</mat-hint>
                  </mat-form-field>
                  <mat-form-field appearance="standard">
                    <mat-label translate>LABEL.LASTNAME</mat-label>
                    <!-- Changing this input triggers a change for the fields in the 'invoice'-tab, too -->
                    <input matInput #lname formControlName="lastName" type="text" required (change)="valueChanged('lname', lname.value)" />
                    <mat-error *ngIf="(userLastName?.dirty || userLastName?.touched) && userLastName?.errors?.required">
                      <span translate>ORDER.HINT.LASTNAME_USER</span>
                    </mat-error>
                    <mat-hint translate>ORDER.HINT.LASTNAME_USER</mat-hint>
                  </mat-form-field>
                  <mat-form-field appearance="standard">
                    <mat-label translate>LABEL.EMAIL</mat-label>
                    <!-- Changing this input triggers a change for the fields in the 'invoice'-tab, too -->
                    <input matInput #email formControlName="email" type="email" required (change)="valueChanged('email', email.value)" />
                    <mat-error *ngIf="(userEmail?.dirty || userEmail?.touched) && userEmail?.errors?.required">
                      <span translate>ORDER.HINT.EMAIL_USER</span>
                    </mat-error>
                    <mat-error *ngIf="(userEmail?.dirty || userEmail?.touched) && userEmail?.errors?.validateEmail">
                      <span translate>ERROR.EMAIL_VALID</span>
                    </mat-error>
                    <mat-hint translate>ORDER.HINT.EMAIL_USER</mat-hint>
                  </mat-form-field>
                  <mat-checkbox class="text-wrap mt-2 pt-3" formControlName="subscribesToNewsletter">
                    <span translate>SIGNUP.SUBSCRIBE_NEWSLETTER</span>
                  </mat-checkbox>
                </div>
              </mat-step>
              <mat-step stepControl="invoiceStep">
                <div formGroupName="invoiceStep" class="order-form">
                  <ng-template matStepLabel>
                    <span translate>ORDER.STEP.INVOICE.HEADLINE</span>
                  </ng-template>
                  <span class="sub-heading" translate>ORDER.STEP.INVOICE.INTRO</span>
                  <mat-form-field appearance="standard">
                    <mat-label translate>LABEL.NAME_OR_ORG</mat-label>
                    <input matInput formControlName="name" type="text" required />
                    <mat-error *ngIf="(invoiceName?.dirty || invoiceName?.touched) && invoiceName?.errors?.required">
                      <span translate>ERROR.NAME_REQUIRED</span>
                    </mat-error>
                    <mat-hint translate>ERROR.NAME_REQUIRED</mat-hint>
                  </mat-form-field>
                  <mat-form-field appearance="standard">
                    <mat-label translate>LABEL.EMAIL</mat-label>
                    <input matInput formControlName="email" type="email" required />
                    <mat-error *ngIf="(invoiceEmail?.dirty || invoiceEmail?.touched) && invoiceEmail?.errors?.required">
                      <span translate>ORDER.ERROR.EMAIL_INVOICE</span>
                    </mat-error>
                    <mat-error *ngIf="(invoiceEmail?.dirty || invoiceEmail?.touched) && invoiceEmail?.errors?.validateEmail">
                      <span translate>ERROR.EMAIL_VALID</span>
                    </mat-error>
                    <mat-hint translate>ORDER.ERROR.EMAIL_INVOICE</mat-hint>
                  </mat-form-field>
                  <mat-form-field appearance="standard">
                    <mat-label translate>LABEL.ADDRESS</mat-label>
                    <input matInput formControlName="address" type="text" required />
                    <mat-error *ngIf="(invoiceAddress?.dirty || invoiceAddress?.touched) && invoiceAddress?.errors?.required">
                      <span translate>ORDER.ERROR.ADDRESS_INVOICE</span>
                    </mat-error>
                    <mat-hint translate>ORDER.ERROR.ADDRESS_INVOICE</mat-hint>
                  </mat-form-field>
                  <div class="d-flex flex-column flex-sm-row">
                    <mat-form-field appearance="standard" class="small-input me-sm-3">
                      <mat-label translate>LABEL.ZIP</mat-label>
                      <input matInput formControlName="zip" type="text" required />
                      <mat-error *ngIf="(invoiceZip?.dirty || invoiceZip?.touched) && invoiceZip?.errors?.required">
                        <span translate>ORDER.ERROR.ZIP_INVOICE</span>
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="small-input">
                      <mat-label translate>LABEL.CITY</mat-label>
                      <input matInput formControlName="city" type="text" required />
                      <mat-error *ngIf="(invoiceCity?.dirty || invoiceCity?.touched) && invoiceCity?.errors?.required">
                        <span translate>ORDER.ERROR.CITY_INVOICE</span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="d-flex align-items-center pt-3">
                    <!-- 
                    As we need clickable links inside the label and material doesn't support disabling clicks on the label itself,
                    we have to construct and style this label by hand.
                  -->
                    <mat-checkbox formControlName="acceptTerms" color="accent" required> </mat-checkbox>
                    <div class="gtc-label ms-3">
                      <span translate>ORDER.LABEL.ACCEPT_TERMS.PREFIX</span>
                      <a
                        class="textlink"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://troodi.de/wp-content/uploads/2021/07/troodi_AGB.pdf">
                        <span translate>ORDER.LABEL.ACCEPT_TERMS.LINK_GTC</span>
                      </a>
                      <span translate>ORDER.LABEL.ACCEPT_TERMS.INFIX</span>
                      <a class="textlink" target="_blank" rel="noopener noreferrer" href="https://troodi.de/impressum/#ds">
                        <span translate>ORDER.LABEL.ACCEPT_TERMS.LINK_PRIVACY</span>
                      </a>
                      <span translate>ORDER.LABEL.ACCEPT_TERMS.POSTFIX</span>
                    </div>
                  </div>
                </div>
              </mat-step>
            </form>
          </mat-horizontal-stepper>
        </div>
        <div class="d-flex flex-column mt-4 mt-lg-0 col basket-container">
          <div class="card-header d-flex justify-content-between align-items-center">
            <span translate>ORDER.BASKET.TITLE</span>
            <div
              class="basket-logo"
              [matBadge]="selectedTrainings.length"
              matBadgeColor="primary"
              matBadgePosition="above"
              matBadgeSize="medium">
              <i-feather name="shopping-bag"></i-feather>
            </div>
          </div>
          <div class="card-content py-3 d-flex flex-column">
            <span *ngIf="selectedTrainings.length === 0" translate class="text-center my-auto">ORDER.BASKET.EMPTY</span>
            <div *ngFor="let training of selectedTrainings; last as last" [@fadeInOutAnimation]="selectedTrainings">
              <div class="d-flex justify-content-between align-items-center">
                <span>{{training.name}}</span>
                <button class="delete" mat-icon-button (click)="removeTraining(training)">
                  <mat-icon svgIcon="delete"></mat-icon>
                </button>
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
            </div>
          </div>
          <div class="card-footer py-3">
            <ng-template [ngTemplateOutlet]="priceContainer"></ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="position-sticky bottom-bar container">
      <div class="row py-2 px-3">
        <button
          *ngIf="isBackAvailable"
          mat-stroked-button
          color="accent"
          (click)="previousStep()"
          class="order-last order-sm-first mt-2 mt-sm-0 secondary w-auto">
          <span translate>LABEL.BACK</span>
        </button>
        <span class="d-none d-sm-flex total px-3 py-2 w-auto">
          <ng-template [ngTemplateOutlet]="priceContainer"></ng-template>
        </span>
        <button *ngIf="isNextAvailable" mat-flat-button color="accent" (click)="nextStep()" class="w-auto">
          <span translate>LABEL.NEXT</span>
        </button>
        <button *ngIf="isFinishAvailable" [disabled]="orderForm.invalid" mat-flat-button color="accent" (click)="submit()" class="w-auto">
          <span translate>ORDER.LABEL.FINISH</span>
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #priceContainer>
  <div class="price-text">
    <span class="price-info" translate> ORDER.BASKET.TOTAL </span>
    <button mat-icon-button (click)="openPriceInfo()">
      <i-feather name="info"></i-feather>
    </button>
  </div>
  <div class="price" *ngIf="(couponChecking$ | async) === false">
    <span class="strikethrough" *ngIf="!!strikethroughPrice" [@fadeInOutAnimation]> {{strikethroughPrice | currency:currencyCode}} </span>
    <span class="total" [@fadeInOutAnimation]> {{priceTotal | currency:currencyCode}} </span>
  </div>
</ng-template>
