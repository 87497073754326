import {Component, Inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {
  PostSignUpCampaignsByIdVerifyResponse,
  SignUpCampaignContent,
} from '@backend-api/sign-up-campaigns-verify/post-sign-up-campaigns-by-id-verify.response';
import {SignUpCampaignsVerifyGateway} from '@backend-api/sign-up-campaigns-verify/sign-up-campaigns-verify.gateway';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {ResourceState} from '@zeit-dev/ngrx-util';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {defaultLanguage} from '../app.component';
import {WINDOW} from '../core/window';
import {Language} from '../features/user/user.data.service';
import {
  signup,
  signupError,
  signupSuccess,
  verifySignupCampaign,
  verifySignupCampaignError,
  verifySignupCampaignSuccess,
} from '../shared/shared.actions';
import {SharedSlice} from '../shared/shared.reducer';
import {selectCampaignParam, selectLanguage, selectSignUpContent} from '../shared/shared.selectors';
import {SignupSuccessDialogComponent} from '../signup-success-dialog/signup-success-dialog.component';

export const validatePassword = Validators.compose([
  Validators.required,
  Validators.minLength(10),
  Validators.pattern(/\d/),
  Validators.pattern(/[a-zA-Z]/),
]);

export interface SignupFormData {
  firstName: string;
  lastName: string;
  organisationName?: string;
  position?: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phone?: string;
  allowsContact: boolean;
  subscribesToNewsletter: boolean;
  hCheckbox: boolean;
  hInput: string;
}

export type SignupData = SignupFormData & {
  language: Language;
  campaign?: string;
  hDelta: number;
};

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  campaignToken?: string;
  error = false;
  loading = false;
  errorType?: 'signupError' | 'notFound' | 'invalid';

  private readonly _selectedLanguage$ = this._store.select(selectLanguage);
  private readonly _campaignToken$ = this._store.select(selectCampaignParam);
  private readonly _subscriptions = new Subscription();
  private selectedLanguage: Language = defaultLanguage;

  private readonly _content$: Observable<ResourceState<PostSignUpCampaignsByIdVerifyResponse>> = this._store.select(selectSignUpContent);
  public readonly content$ = new BehaviorSubject<SignUpCampaignContent>({benefits: [], headline: ''});

  // for honeypots
  private readonly _hTimestamp = Date.now();

  constructor(
    private readonly _store: Store<SharedSlice>,
    private readonly _dialog: MatDialog,
    private readonly _actions$: Actions,
    private readonly _translate: TranslateService,
    private readonly _signupCampaignsGateway: SignUpCampaignsVerifyGateway,
    @Inject(WINDOW) private readonly _window: Window
  ) {
    // app-component already figured out which language to use with fallbacks etc.
    // use this in the signup
    this.selectedLanguage = this._translate.currentLang as Language;
  }

  ngOnInit(): void {
    this._subscriptions.add(
      this._selectedLanguage$.subscribe((language) => {
        if (language) {
          this.selectedLanguage = language;
          this.setContentByLanguage();
        }
      })
    );
    this._subscriptions.add(
      this._actions$.pipe(ofType(signupSuccess)).subscribe(() => {
        this._dialog.open(SignupSuccessDialogComponent);
      })
    );
    this._subscriptions.add(
      this._actions$.pipe(ofType(signupError)).subscribe(() => {
        this.error = true;
        this.errorType = 'signupError';
      })
    );
    // get the token from URL-Params and check it
    this._campaignToken$.pipe(take(1)).subscribe((token) => {
      this.campaignToken = token || undefined;

      token = token || 'default';

      // for the template and form
      // check validity in BE
      this._store.dispatch(verifySignupCampaign({key: token}));
      this.loading = true;
    });
    // handle this with subscriptions this time, omit the store-boilerplate ;)
    this._subscriptions.add(
      this._actions$.pipe(ofType(verifySignupCampaignError)).subscribe((result) => {
        this.loading = false;
        this.error = true;
        if (result.found) {
          this.errorType = 'invalid';
        } else {
          this.errorType = 'notFound';
        }
      })
    );
    this._subscriptions.add(
      this._actions$.pipe(ofType(verifySignupCampaignSuccess)).subscribe(() => {
        // just to be sure
        this.loading = false;
        this.error = false;
        this.setContentByLanguage();
      })
    );
  }

  signup(e: {data: SignupFormData}) {
    this._store.dispatch(
      signup({
        signupData: {
          firstName: e.data.firstName,
          lastName: e.data.lastName,
          organisationName: e.data.organisationName,
          position: e.data.position,
          email: e.data.email,
          password: e.data.password,
          passwordConfirmation: e.data.passwordConfirmation,
          phone: e.data.phone,
          language: this.selectedLanguage,
          campaign: this.campaignToken,
          // flag 'temporarily' removed
          allowsContact: false,
          subscribesToNewsletter: e.data.subscribesToNewsletter,
          // Honeypot-Data
          hCheckbox: e.data.hCheckbox,
          hInput: e.data.hInput,
          hDelta: Date.now() - this._hTimestamp,
        },
      })
    );
  }

  /**
   * If the campaign is invalid, the user can navigate to the signup without campaign-key.
   * For this, we have to 'clear' the component-state and remove the query-params.
   */
  resetCampaignData() {
    this.loading = false;
    this.error = false;
    this.errorType = undefined;
    this.campaignToken = undefined;
    // remove query-params
    const url = new URL(this._window.location.href);
    url.search = '';
    this._window.history.pushState('', '', url.toString());
  }

  private setContentByLanguage() {
    this._content$
      .subscribe((content) => {
        this.content$.next(content.results.content[this.selectedLanguage]);
      })
      .unsubscribe();
  }
}
