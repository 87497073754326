import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {validateEmail} from 'src/app/forgot-password/forgot-password.component';
import {passwordsMatchValidator} from 'src/app/reset-password/reset-password.component';
import {SignupFormData, validatePassword} from '../signup.component';

@Component({
  selector: 'app-signup-form-default',
  templateUrl: './signup-form-default.component.html',
  styleUrls: ['./signup-form-default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupFormDefaultComponent {
  @Output() readonly submitSignup = new EventEmitter<{data: SignupFormData}>();

  signupForm = new FormGroup({
    signupPage1: new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      organisationName: new FormControl('', Validators.required),
      position: new FormControl(''),
      // honeypots
      hCheckbox: new FormControl(false),
      hInput: new FormControl(''),
    }),
    signupPage2: new FormGroup(
      {
        phone: new FormControl(''),
        email: new FormControl('', [Validators.required, validateEmail]),
        password: new FormControl('', validatePassword),
        passwordConfirmation: new FormControl('', []),
        subscribesToNewsletter: new FormControl(false),
      },
      {
        validators: passwordsMatchValidator,
      }
    ),
  });

  submit() {
    if (!this.firstPage || !this.secondPage) return;
    this.submitSignup.emit({
      data: {
        ...this.firstPage.value,
        ...this.secondPage.value,
      },
    });
  }

  // getters needed for the validators
  get firstName() {
    return this.firstPage?.get('firstName');
  }
  get lastName() {
    return this.firstPage?.get('lastName');
  }
  get organisationName() {
    return this.firstPage?.get('organisationName');
  }
  get position() {
    return this.firstPage?.get('position');
  }
  get email() {
    return this.secondPage?.get('email');
  }
  get password() {
    return this.secondPage?.get('password');
  }
  get passwordConfirmation() {
    return this.secondPage?.get('passwordConfirmation');
  }
  get phone() {
    return this.secondPage?.get('phone');
  }
  get subscribesToNewsletter() {
    return this.secondPage?.get('subscribesToNewsletter');
  }
  // Getters for honeypot-fields
  get hCheckbox() {
    return this.firstPage?.get('hCheckbox');
  }
  get hInput() {
    return this.firstPage?.get('hInput');
  }
  // 'shortcuts' to the corresponding FormGroups
  get firstPage() {
    return this.signupForm.get('signupPage1');
  }
  get secondPage() {
    return this.signupForm.get('signupPage2');
  }
}
