import {Injectable} from '@angular/core';
import {SignUpCampaignsVerifyGateway} from '@backend-api/sign-up-campaigns-verify/sign-up-campaigns-verify.gateway';
import {VisitorCookieSettingGateway} from '@backend-api/visitor-cookie-setting/visitor-cookie-setting.gateway';
import {defaultLanguage} from '../app.component';
import {ConfirmationGateway} from '../backend/public-user-api/confirmation/confirmation.gateway';
import {PasswordGateway} from '../backend/public-user-api/password/password.gateway';
import {RegistrationGateway} from '../backend/public-user-api/registration/registration.gateway';
import {Language} from '../features/user/user.data.service';
import {SignupData} from '../signup/signup.component';
import {PrivacySettings} from './privacy.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private acceptLanguage: Language = defaultLanguage;
  private readonly _defaultCampaign = 'default';

  constructor(
    private readonly _passwordGateway: PasswordGateway,
    private readonly _registrationGateway: RegistrationGateway,
    private readonly _confirmationGateway: ConfirmationGateway,
    private readonly _cookiesGateway: VisitorCookieSettingGateway,
    private readonly _signupCampaignsGateway: SignUpCampaignsVerifyGateway
  ) {}

  setLanguage(language: Language) {
    this.acceptLanguage = language;
  }

  sendResetMail(email: string) {
    return this._passwordGateway.postPassword({email}, {headers: {'Accept-Language': this.acceptLanguage}});
  }

  resetPassword(token: string, password: string, confirmation: string) {
    return this._passwordGateway.putPassword(
      {
        reset_password_token: token,
        password,
        password_confirmation: confirmation,
      },
      {headers: {'Accept-Language': this.acceptLanguage}}
    );
  }

  signupNewUser(signupData: SignupData) {
    return this._registrationGateway.postRegistration(
      {
        first_name: signupData.firstName,
        last_name: signupData.lastName,
        organisation_name: signupData.organisationName ?? '',
        position: signupData.position ?? '',
        email: signupData.email,
        password: signupData.password,
        password_confirmation: signupData.passwordConfirmation,
        phone: signupData.phone ?? '',
        language: signupData.language,
        campaign_key: signupData.campaign ?? this._defaultCampaign,
        allows_contact: signupData.allowsContact,
        subscribe_newsletter: signupData.subscribesToNewsletter,
        h_checkbox: signupData.hCheckbox,
        h_delta: signupData.hDelta,
        h_input: signupData.hInput,
      },
      {headers: {'Accept-Language': this.acceptLanguage}}
    );
  }

  confirmUserAccount(token: string) {
    return this._confirmationGateway.postConfirmation({confirmation_token: token}, {headers: {'Accept-Language': this.acceptLanguage}});
  }

  setVisitorCookieSetting(settings: PrivacySettings) {
    return this._cookiesGateway.postVisitorCookieSetting({
      matomo_enabled: settings.matomo,
    });
  }

  verifySignupCampaign(id: string) {
    return this._signupCampaignsGateway.postSignUpCampaignsByIdVerify(id, {});
  }
}

export interface Dictionary<T, K extends number | string = string> {
  [key: string]: T;
}

export interface IndexedObjects<T, K extends number | string> {
  ids: K[];
  objects: Dictionary<T, K>;
}

// Builds a `IndexedObjects` from an array based on the given mapping functions for key/value
export function indexObjects<T, V, K extends number | string>(source: T[], keyFn: (x: T) => K, valueFn: (x: T) => V): IndexedObjects<V, K> {
  const objects: IndexedObjects<V, K>['objects'] = {} as IndexedObjects<V, K>['objects'];
  const ids = source.map((object) => {
    objects[keyFn(object)] = valueFn(object);
    return keyFn(object);
  });
  return {ids, objects};
}

// Builds an array from the objects of an `IndexedObjects`, preserving the ordering of ids
export function extractObjects<T, K extends number | string>(source: IndexedObjects<T, K>): T[] {
  return source.ids.map((id) => source.objects[id]);
}
